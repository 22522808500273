import slick from "slick-carousel";
import fancybox from "@fancyapps/fancybox";
import { popover, tooltip } from "bootstrap";
import cookie from "jquery.cookie";

$(function () {
    var baseUrl = window.location.protocol + "//" + window.location.host;

    // popover
    $(function () {
        $('[data-toggle="popover"]').popover()
    });

    // fancybox
    $("a.fancybox").fancybox({
        loop: true,
        keyboard: true,
    });

    var time = 2;
    var bar,
        slick,
        isPause,
        tick,
        percentTime;

    slick = $('.text-slider__slider');
    if (slick.length) {
        slick.slick({
            arrows: false,
            dots: false,
            speed: 0,
            adaptiveHeight: false,
            autoplay: false,
            fade: true,
            asNavFor: '.text-slider__photo-slider',
        });

        bar = $('.text-slider__progress');

        function startProgressbar() {
            resetProgressbar();
            percentTime = 0;
            isPause = false;
            tick = setInterval(interval, 30);
        }

        function interval() {
            if (isPause === false) {
                percentTime += (1 / (time + 0.1));
                bar.css({
                    width: percentTime + "%"
                });
                if (percentTime >= 100) {
                    slick.slick('slickNext');
                    startProgressbar();
                }
            }
        }

        function resetProgressbar() {
            bar.css({
                width: 0 + '%'
            });
            clearTimeout(tick);
        }

        startProgressbar();

        $('.slick-next, .slick-prev').click(function () {
            startProgressbar();
        });

        $('.text-slider__photo-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: '.text-slider__slider',
            dots: false,
            arrows: false,
            centerMode: false,
            focusOnSelect: false,
            fade: true,
            speed: 1500,
        });
    }



    // Reviews slider
    $('.reviews').slick({
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 6000,
        centerMode: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            },
        ]
    });

    // rate help center article
    $(".rate-article--help").on("click", function () {
        var that = $(this);
        var id = that.data("id");
        var value = that.data("value");
        var url = baseUrl + "/centrum-pomocy/rate-article-action";

        $('<div class="page__subtitle text-success">Dziękujemy za twoją opinię</div>').insertAfter(that);
        $(".rate-article--help").remove();

        $.ajax({
            method: "POST",
            url: url,
            data: {
                id: id,
                value: value
            },
            dataType: "json",
            complete: function (jqXHR, textStatus) { }
        });
    });

    // copy ref link to clipboard
    var reflinkInput = $("#reflinkInput");
    var reflinkbutton = $("#reflinkButton");
    if (reflinkInput && reflinkbutton) {
        reflinkbutton.on("click", function () {
            reflinkInput.trigger("select").trigger("focus");
            document.execCommand("copy");
        });
    }

    // method slider
    $('.page-slider').slick({
        dots: true,
        prevArrow: '<div class="prev"><i class="material-icons">navigate_before</i></div>',
        nextArrow: '<div class="next"><i class="material-icons">navigate_next</i></div>',
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 6000,
        centerMode: false,
    });
});
